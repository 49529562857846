import Paragraph from '../Paragraph/Paragraph'
import React from 'react'
import PropTypes from 'prop-types'

const ImprintParagraph = ({ header, body }) =>
  <div className='imprint-part'>
    <div className='imprint-part__header'>{header}</div>
    <Paragraph
      className='imprint-part__body'
      text={body}
    />
  </div>

export default ImprintParagraph

ImprintParagraph.propTypes = {
  header: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
}
