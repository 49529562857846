import React from 'react'
import PropTypes from 'prop-types'

const ImprintContact = ({ header, phoneNumber, faxNumber, email }) =>
  <div className='imprint-part'>
    <div className='imprint-part__header'>{header}</div>
    <div className='imprint-part__body'>Telefon:
      <a
        className='phone-number'
        href={`tel:${phoneNumber}`}
      >
        {phoneNumber}
      </a>
    </div>
    <div className='imprint-part__body'>TeleFax: {faxNumber}</div>
    <div className='imprint-part__body'>E-Mail:
      <a
        className='email'
        href={`mailto:${email}`}
      >
        {email}
      </a>
    </div>
  </div>

export default ImprintContact

ImprintContact.propTypes = {
  header: PropTypes.string.isRequired,
  phoneNumber: PropTypes.string.isRequired,
  faxNumber: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired
}
