import Paragraph from '../Paragraph/Paragraph'
import React from 'react'
import PropTypes from 'prop-types'

const ImprintParagraphWithLink = ({ header, paragraphElements }) =>
  <div className="imprint-part">
    <div className="imprint-part__header">{header}</div>
    {paragraphElements.map(element => element.isLink ?
      <div className="imprint-part__body">
        <a
          target='_blank'
          className="email link"
          href={element.text}
        >
          {element.text}
        </a>.
      </div>
      : <Paragraph
        className="imprint-part__body"
        text={element.text}
      />
    )}
  </div>

export default ImprintParagraphWithLink

ImprintParagraphWithLink.propTypes = {
  header: PropTypes.string.isRequired,
  paragraphElements: PropTypes.arrayOf({
    text: PropTypes.string.isRequired,
    isLink: PropTypes.bool
  }),
}
