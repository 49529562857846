import React from 'react'
import Imprint from '../components/Imprint/Imprint'
import Layout from '../components/Layout/Layout'
import { imprintData } from '../components/consts'

const ImpressumPage = () =>
  <Layout description='Impressum'>
    <Imprint {...imprintData} />
  </Layout>

export default ImpressumPage
