import React from 'react'
import SectionWrapper from '../SectionWrapper/SectionWrapper'
import ImprintParagraph from './ImprintParagraph'
import ImprintContact from './ImprintContact'
import PropTypes, { arrayOf, oneOfType } from 'prop-types'
import { ImprintContactPropType, ImprintParagraphPropType } from '../propTypes'
import ImprintParagraphWithLink from './ImprintParagraphWithLink'

const Imprint = ({ header, imprintParts }) =>
  <SectionWrapper className='imprint'>
    <div className='imprint__header'> {header}</div>
    {imprintParts.map((imprintPart, index) =>
      imprintPart.type === 'contact'
        ? <ImprintContact key={index} {...imprintPart} />
        : imprintPart.type === 'paragraphsWithLinks'
          ? <ImprintParagraphWithLink key={index} {...imprintPart}/>
          : <ImprintParagraph key={index} {...imprintPart} />
    )}
  </SectionWrapper>

export default Imprint

Imprint.propTypes = {
  header: PropTypes.string.isRequired,
  imprintParts: arrayOf(oneOfType([ImprintParagraphPropType, ImprintContactPropType])).isRequired,
}
